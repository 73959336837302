import React from 'react';
import {Button, Col, Form, Input, Row, Tabs} from "antd";

const formInit = {
    _id: null,
    title: {},
    productName: {},
    manufacturer: {},
    qualityClass: {},
    material: {},
    countryOfOrigin: {},
    importer: {}
};

const DeclarationForm = ({isNew, data, language, onSubmit}) => {
    const [form] = Form.useForm();
    let initialValues = { ...formInit, ...data };

    const onFinish = async (values, isNew) => {
        onSubmit(values, isNew);
    };

    return(
        <div className='panel panel-body'>
            <div className='panel-body'>
                <Form className='form-horizontal' initialValues={initialValues} onFinish={(values) => onFinish(values, !data)} layout='vertical' form={form}>
                    <Row type='flex' gutter={16}>
                        <Col xs={24} md={16}>
                            <div className='panel-body'>
                                <Tabs tabPosition='left' defaultActiveKey={language.default.code}>
                                    {language.list.map((lang) => (
                                        <Tabs.TabPane tab={lang.code} key={lang.code}>
                                            <Form.Item
                                                label='Declaration name'
                                                rules={[{ required: lang.code === language.default.code ? true : false, message: 'Please enter declaration name!' }]}
                                                name={['title', lang.code]}
                                            >
                                                <Input />
                                            </Form.Item>

                                            <Form.Item label='Product name' name={['productName', lang.code]}>
                                                <Input />
                                            </Form.Item>

                                            <Form.Item label='Manufacturer' name={['manufacturer', lang.code]}>
                                                <Input />
                                            </Form.Item>

                                            <Form.Item label='Quality class' name={['qualityClass', lang.code]}>
                                                <Input />
                                            </Form.Item>

                                            <Form.Item label='Material' name={['material', lang.code]}>
                                                <Input />
                                            </Form.Item>

                                            <Form.Item label='Country of origin' name={['countryOfOrigin', lang.code]}>
                                                <Input />
                                            </Form.Item>

                                            <Form.Item label='Importer' name={['importer', lang.code]}>
                                                <Input />
                                            </Form.Item>
                                        </Tabs.TabPane>
                                    ))}
                                </Tabs>
                            </div>
                        </Col>
                    </Row>
                    <div className='text-right'>
                        <Button type='primary' htmlType='submit'>
                            {isNew ? 'Add' : 'update'} declaration
                        </Button>
                    </div>
                </Form>
            </div>
        </div>
    )
}

export default DeclarationForm;
import React, { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import { GalleryTable } from '../../components/tables';

const TABLE_COLUMN_KEYS = ['_id', '__v', 'key', 'updatedAt', 'uploadedBy', 'url', 'forMobile', 'localPath'];

const Gallery = (props) => {
  const currentuser = useContext(UserContext);
  const [images, fetchImages] = useAxios('', null, currentuser.data.token, 'get');
  const { id } = props.match.params;

  useEffect(() => {
    fetchImages(`${SERVER_URL}/images`);
  }, [id, fetchImages]);

  let columnKeys;
  if (images && images.data && images.data.length > 0) {
    const keys = Object.keys(images.data[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
  }

  let tableData = [];
  if (images && images.data && images.data.length > 0) {
    tableData = images.data.map((item) => {
      item.createdAt = new Date(item.createdAt).toLocaleString();
      item.updatedAt = new Date(item.updatedAt).toLocaleString();
      return item;
    });
  }

  return (
    <div className='content-wrapper'>
      <div className='actions-block'>
        <Link to='/admin/gallery/new-content'>
          <Button type='primary'>Add new content</Button>
        </Link>
      </div>

      <div className='content'>
        {images && images.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem', marginLeft: '50%', transform: 'translateX(-50%)' }} />}
        {images && !images.isLoading && images.isError && <h2 style={{ marginTop: '5rem' }}>Something went wrong :(</h2>}
        {images && !images.isLoading && !images.isError && images.data && images.data.length > 0 && (
          <GalleryTable data={tableData} columnKeys={columnKeys} title='View Gallery' />
        )}
        {images && images.data && !images.isLoading && images.data.length === 0 && <h2 style={{ marginTop: '3rem', textAlign: 'center' }}>NO IMAGES</h2>}
      </div>
    </div>
  );
};

export default Gallery;

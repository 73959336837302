import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Tabs, Divider, Input, Select, Button, Form } from 'antd';
import useAxios from '../../hooks/useAxios';
import UploadBox from '../base/UploadBox';

let formInit = {
  name: {},
  SEOTitle: {},
  SEODescription: {},
  SEOKeywords: {},
  image: undefined,
  loading: false,
  attributes: [],
};

const CategoryForm = ({ onSubmit, category, token, language, setEdit, SERVER_URL, categoryImage }) => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [tab, setTab] = useState();
  const [attributes, fetchAttributes] = useAxios('', [], token, 'get');
  ['children', 'createdAt', 'path', 'updatedAt', '__v'].forEach((key) => delete category[key]);

  let initialValues = { ...formInit, ...category };

  const prevForm = sessionStorage.getItem('categoryForm');
  if (prevForm) initialValues = JSON.parse(prevForm);

  useEffect(() => {
    fetchAttributes(`${SERVER_URL}/attributes?filter={"isGlobal":false}`, []);
    if (language && language.selected) setTab(language.selected.code);
    form.resetFields();
  }, [fetchAttributes, language, SERVER_URL, category, form/*, categoryImage */]);

  let image;
  if (category && category.image) image = category.image.url;
  if (categoryImage && categoryImage.url) {
    image = categoryImage.url;
    form.setFieldsValue({ image });
  }

  const onFinish = async (values, isNew) => {
    sessionStorage.removeItem('categoryForm');
    if (!isNew) {
      values._id = category._id;
    }
    if (categoryImage) {
      values.image = categoryImage._id ? categoryImage._id : undefined;
    }
    onSubmit(values, isNew);
  };

  const imageHandler = (isNew) => {
    const values = form.getFieldsValue();
    sessionStorage.setItem('categoryForm', JSON.stringify(values));
    history.push(`/admin/gallery/edit-content?fromPage=${isNew ? 'data-categories/new' : 'data-categories/' + category._id}${values.image ? '&imageIds=' + values.image._id.toString() : ''}`);
  };

  return (
    <div className='panel panel-primary'>
      <div className='panel-heading'>
        <h4 className='panel-title'>
          <span>{!category.name ? 'ADD' : 'EDIT'}</span>
          <span
            style={{ cursor: 'pointer', padding: '6px', background: 'red' }}
            onClick={() => {
              setEdit({ visible: false, category: {} });
            }}
          >
            X
          </span>
        </h4>
      </div>

      <div className='panel-body'>
        {attributes.isLoading && <h2 style={{ textAlign: 'center' }}>LOADING</h2>}
        <Form initialValues={initialValues} onFinish={(values) => onFinish(values, !category.name)} layout='vertical' form={form}>
          <h3 style={{ marginBottom: '8px' }}>{tab} language</h3>
          <Tabs onChange={(key) => setTab(key)} tabPosition='left' defaultActiveKey={language ? language.default.code : undefined}>
            {language.list.map((lang) => (
              <Tabs.TabPane tab={lang.code} key={lang.code}>
                <Form.Item
                  label='Name'
                  rules={[{ required: lang.code === language && language.default.code ? true : false, message: 'Please enter category name!' }]}
                  name={['name', lang.code]}
                >
                  <Input />
                </Form.Item>
                <Form.Item label='SEO Title' name={['SEOTitle', lang.code]}>
                  <Input />
                </Form.Item>
                <Form.Item label='SEO Description' name={['SEODescription', lang.code]}>
                  <Input />
                </Form.Item>
                <Form.Item label='SEO Keywords' name={['SEOKeywords', lang.code]}>
                  <Input />
                </Form.Item>
              </Tabs.TabPane>
            ))}
          </Tabs>

          <Divider type='horizontal' />

          <Form.Item label='Attributes' name='attributes'>
            <Select mode='multiple'>
              {!attributes.isLoading && attributes.data &&
                attributes.data.items &&
                attributes.data.items.length > 0 &&
                attributes.data.items.map((attr) => (
                  <Select.Option value={attr._id} key={attr._id}>
                    {attr.name[language.selected.code]}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item label='Image' name='image' valuePropName='image'>
            <UploadBox handler={() => imageHandler(!category._id)} image={image} />
          </Form.Item>

          <div className='text-right'>
            <Button type='primary' htmlType='submit'>
              Submit
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default CategoryForm;

import React, { useState, useEffect } from 'react';
import { Tabs, Divider, Input, Select, Button, Form, Checkbox } from 'antd';

const ATTR_TYPES = ['CHOICE', 'MULTICHOICE', 'INPUT', 'NUMBER', 'CHECKBOX' /* , 'DATE' */];

const formInit = {
  name: {},
  type: '',
  unit: '',
  isRequired: false,
  isGlobal: false,
  includeInSearch: false,
};

const AttributeForm = ({ setEdit, attribute, language, onSubmit }) => {
  const [form] = Form.useForm();
  const [tab, setTab] = useState(language.selected.code);
  const editAttr = { ...attribute };
  [('createdAt', 'updatedAt', '__v', 'values')].forEach((key) => delete editAttr[key]);
  let initialValues = { ...formInit, ...editAttr };

  useEffect(() => {
    form.resetFields();
  }, [attribute, form]);

  return (
    <div className='panel panel-primary'>
      <div className='panel-heading'>
        <h4 className='panel-title'>
          <span>ADD / EDIT ATTRIBUTE</span>
          <span
            style={{ cursor: 'pointer', padding: '6px', background: 'red' }}
            onClick={() => {
              setEdit({ visible: false, attribute: {} });
            }}
          >
            X
          </span>
        </h4>
      </div>
      <div className='panel-body'>
        <Form initialValues={initialValues} onFinish={(values) => onSubmit(values, !attribute.name)} layout='vertical' form={form}>
          <h3 style={{ marginBottom: '8px' }}>{tab} language</h3>
          <Tabs onChange={(key) => setTab(key)} tabPosition='left' defaultActiveKey={language.default.code}>
            {language.list.map((lang) => (
              <Tabs.TabPane tab={lang.code} key={lang.code}>
                <Form.Item
                  label='Name'
                  rules={[{ required: lang.code === language.default.code ? true : false, message: 'Please enter attribute name!' }]}
                  name={['name', lang.code]}
                >
                  <Input />
                </Form.Item>
              </Tabs.TabPane>
            ))}
          </Tabs>
          <Divider type='horizontal' />
          <Form.Item label='Type' name='type' rules={[{ required: true, message: 'Please select attribute type!' }]}>
            <Select>
              {ATTR_TYPES.map((type) => (
                <Select.Option value={type} key={type}>
                  {type}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label='Unit' name='unit'>
            <Input />
          </Form.Item>
          <Form.Item label='Required' name='isRequired' valuePropName='checked'>
            <Checkbox />
          </Form.Item>
          <Form.Item label='Global attribute' name='isGlobal' valuePropName='checked'>
            <Checkbox />
          </Form.Item>
          <Form.Item label='Include in Search' name='includeInSearch' valuePropName='checked'>
            <Checkbox />
          </Form.Item>
          <div className='text-right'>
            <Button type='primary' htmlType='submit'>
              Submit
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default AttributeForm;

import React from 'react';

const Home = () => {
  return (
    <div style={{ textAlign: 'center' }}>
      <h2>HOME</h2>
    </div>
  );
};

export default Home;

import React from 'react';
import { Table, Popconfirm } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

const ValueTable = ({ values, getColumnSearchProps, editValueHandler, deleteValueHandler, language, attrId }) => {
  const valueColumns = [
    {
      title: 'Value',
      dataIndex: ['value', language.selected.code],
      ...getColumnSearchProps(['value', language.selected.code]),
    },
    {
      title: 'Action',
      width: 70,
      render: (text, record, index) => (
        <div className='table-actions'>
          <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} title='Edit value' onClick={() => editValueHandler(attrId, record._id)}>
            <EditOutlined />
          </div>
          <div style={{ margin: '2px', padding: '4px' }}>
            <Popconfirm
              style={{ margin: '2px', padding: '4px' }}
              placement='left'
              title={`This will delete value ${record.value[language.selected.code] ? record.value[language.selected.code].toLowerCase() : ''}`}
              onConfirm={() => deleteValueHandler(attrId, record._id)}
              okText='Ok'
              cancelText='Cancel'
            >
              <DeleteOutlined />
            </Popconfirm>
          </div>
        </div>
      ),
    },
  ];

  return <Table className='table-striped-rows' pagination={false} dataSource={values} columns={valueColumns} rowKey='_id' />;
};

export default ValueTable;
